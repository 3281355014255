* {
  font-family: "Barlow", sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #23252c;
}

/* ------------------------ CREATE PAGE ------------------------------ */
.setup {
  margin: auto;
  width: 500px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;
}

.app-header {
  display: flex;
  background-color: #161619;
  margin-top: 20px;
  font-size: 42px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: white;
}

.logo {
  margin: 35px 0px 0px 45px;
  height: 75px;
  width: 75px;
}

.app-title {
  height: 50px;
  margin-top: 50px;
  width: 300px;
  border-radius: 6px;
}

.button-setup {
  max-width: 200px;
  margin: auto;
  margin-top: 250px;
}

.connect-wallet {
  min-width: 200px;
  margin-bottom: 75px;
  background-color: transparent;

  color: white;
  font-size: 18px;
  border-radius: 10px;
  border: 2px solid white;
}

.create-wallet {
  min-width: 200px;
  background-color: transparent;
  color: #22c1fd;

  font-size: 18px;
  border-radius: 10px;
  border: 2px solid #22c1fd;

  box-shadow: none;
}

.connect-wallet:hover {
  opacity: 0.3;
}

.create-wallet:hover {
  opacity: 0.3;
}

.create-page {
  margin: auto;
  width: 500px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;
}

.username-password {
  justify-content: center;
  color: white;
}

.password {
  margin-left: 3px;
}

.create-wallet-text {
  color: white;
}

.enrollAndRegister {
  margin-top: 50px;
  font-size: 18px;
  height: 60px;
  min-width: 200px;
  border-radius: 10px;
  background-color: transparent;
  color: #22c1fd;
  border: 2px solid #22c1fd;
}

.enrollAndRegister:hover {
  opacity: 0.3;
}

/* -----------------------------Import Private Key-------------------- */
.import-page {
  margin: auto;
  width: 500px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;

  color: white;
}

.backToConnect {
  margin: 10px 0px 50px 400px;
  height: 30px;
  width: 80px;
  background-color: transparent;
  color: white;

  border-radius: 15px;
  border: 2px solid white;
}

.backToConnect:hover {
  opacity: 0.3;
}

.connect-body-pk {
  font-size: 14px;
}

.import-pk-here {
  margin-top: 150px;
}

.private-key {
  margin-left: 0px;
}

.connect-wallet-pk-text {
  margin-top: 35px;
}

.keys {
  margin-top: 25px;
  justify-content: center;
  color: white;
}

.connect-pk {
  margin-top: 100px;
  font-size: 18px;
  height: 60px;
  min-width: 200px;
  border-radius: 10px;
  background-color: transparent;
  color: #22c1fd;
  border: 2px solid #22c1fd;
}

.connect-pk:hover {
  opacity: 0.3;
}

/* ------------------------------ HOME ------------------------------- */
.home-page {
  margin: auto;
  width: 600px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;
}

.home-header {
  background-color: #1e1e1f;
  display: flex;
  text-align: left;
  height: 125px;
  margin-top: 50px;
  max-width: 600px;
  border: 3px solid transparent;
  border-radius: 6px;
  box-shadow: black;
}

.header-home-title {
  margin-right: auto;
}

.username {
  font-size: 36px;
  color: white;
  margin: 10px auto 10px 15px;
}

.address {
  font-size: 13px;
  color: white;
  margin: 10px auto 10px 15px;
  inline-size: 450px;
  overflow-wrap: anywhere;
}

.account-link {
  justify-content: right;
  position: left;

  margin: 5px 0px 50px 10px;

  font-size: 18px;
  height: 85px;
  background-color: transparent;
  color: white;
  border-radius: 100px;
  border: 3px solid white;
}

.account-link:hover {
  opacity: 0.5;
}

.balance {
  display: flex;
  margin: 100px auto 0px auto;
  height: 100px;
  width: 550px;
  background-color: #161619;
  border: 3px groove transparent;
  border-radius: 6px;
}

.balance-title {
  margin: auto auto auto 15px;
  color: white;
}

.balance-box {
  background-color: rgb(61, 56, 56);
  margin: auto auto 30px 15px;
  height: 35px;
  width: 250px;
  border: 3px solid transparent;
}

.balance-amt {
  text-align: left;
  margin: 8px auto auto 10px;
  color: white;
}

.send-btn {
  height: 35px;
  width: 90px;
  /* margin: 5px 5px; */
  margin: 32px 5px;
  text-align: center;
  padding-left: 10px;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border-radius: 6px;
  border: 2px solid #22c1fd;
}

.send-btn:hover {
  opacity: 0.3;
}

/* -------------------------- TXT DATA -------------------------------- */

.tx-wrapper {
  margin: 5rem auto 0rem auto;
  font-size: 1.8rem;
  color: white;

  background-color: #161619;
  max-height: 32rem; /* 500px / 16px */
  height: 29.85rem;
  width: 34.375rem; /* 550px / 16px */
  border-radius: 6px;
  border: 2px solid transparent;
  /* display: flex;
    flex-direction: column; */
  overflow: scroll;
}

.tx-box {
  /* height: 3rem;
  width: auto; */
  display: grid;
  grid-template-rows: minmax(2rem, 3rem);
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-areas:
    "type type price"
    "date loc  price";

  /* gap: 0.1rem; */
  /* padding: 1rem; */
  background-color: rgb(61, 56, 56) (54, 54, 66);
  /* margin: 0.625rem; */
  border-bottom: 0.5px solid white;
  border-radius: 5px;
}

/* .tx-box > p {
  font-size: 24px;
  padding-left: 1rem;
} */

.tx-type {
  grid-area: type;
  text-align: left;
  font-weight: bold;
  padding-left: 2.5rem;
}
.tx-date {
  grid-area: date;
  font-size: 1.5rem;
  text-align: left;
  padding-left: 2.5rem;
}

.tx-loc {
  grid-area: loc;
  font-size: 1.5rem;
  text-align: left;
  padding-left: 2.5rem;
}
.tx-amount {
  grid-area: price;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

/* --------------------- ACCOUNT SCREEN ------------------------------ */

.account-page {
  margin: auto;
  width: 500px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;
}

.cancel-btn {
  margin: 60px 0px auto 250px;
  width: 100px;
  background-color: transparent;
  color: white;

  font-size: 18px;
  border-radius: 10px;
  border: 2px solid white;
}

.body-btns {
  margin-top: 250px;
}

.lock-btn {
  min-width: 200px;
  height: 60px;
  background-color: transparent;
  color: white;

  font-size: 18px;
  border-radius: 10px;
  border: 2px solid white;
}

.settings-btn {
  margin-top: 100px;
  min-width: 200px;
  height: 60px;
  background-color: transparent;
  color: #22c1fd;

  font-size: 18px;
  border-radius: 10px;
  border: 2px solid #22c1fd;
}

.cancel-btn:hover {
  opacity: 0.3;
}

.lock-btn:hover {
  opacity: 0.3;
}

.settings-btn:hover {
  opacity: 0.3;
}

.account-screen-header {
  display: flex;
  margin: auto;
  justify-content: baseline;
  background-color: #161619;
  color: white;
  padding-bottom: 25px;
}

/* ----------------------- CONNECT PAGE ------------------------------ */
body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #23252c;
}

.connect-page {
  margin: auto;
  width: 500px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;
}

.backToSetup {
  margin: 10px 0px 50px 400px;
  height: 30px;
  width: 80px;
  background-color: transparent;
  color: white;

  border-radius: 15px;
  border: 2px solid white;
}

.backToSetup:hover {
  opacity: 0.3;
}

.unlock {
  margin: 15px 0px 10px 0px;
  height: 40px;
  width: 170px;
  background-color: transparent;
  color: white;
  font-size: 18px;

  border-radius: 15px;
  border: 2px solid white;
}

.unlock:hover {
  opacity: 0.3;
}

.connect-body {
  margin-top: 020px;
  padding: 40px 0px 100px 0px;
}

.connect-body > h1 {
  margin: 30px 0px 80px 0px;
  color: white;
}

.seedphrase-link {
  margin: 225px auto 0px auto;
  width: 300px;
  color: #22c1fd;
  background-color: transparent;
}

.seedphrase-link:hover {
  opacity: 0.3;
}

/* -------------------------- SEND PAGE ------------------------------- */
.send-page {
  margin: auto;
  /* padding-top: 20px; */
  width: 500px;
  height: 900px;
  background-color: #1a1b21;
  border-radius: 10px;
  color: white;
}

.send-header {
  display: flex;
  background-color: #1e1e1f;
  margin-top: 20px;
  font-size: 42px;
  color: white;
  justify-content: center;
}

.cancel-send-btn {
  margin: 20px 0px 50px 400px;
  height: 30px;
  width: 80px;
  background-color: transparent;
  color: white;

  border-radius: 15px;
  border: 2px solid white;
}

.cancel-send-btn:hover {
  opacity: 0.3;
}

.recipient-info {
  padding-top: 0px;
  font-size: 24px;
}

.address_search {
  display: flex;
  margin: auto;
  width: 200px;
  height: 30px;
  /* margin: auto auto auto 100px; */
  justify-content: center;
}

.amount {
  display: flex;
  margin: -30px auto 0 auto;
  width: 200px;
  height: 30px;
  /* margin: auto auto auto 100px; */
  justify-content: center;
}

.submit-send {
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: 2px solid #22c1fd;
  border-radius: 5px;
  justify-content: center;
}

.submit-send:hover {
  opacity: 0.3;
}

.recent {
  margin-top: 100px;
  padding-top: 10px;
  font-size: 22px;

  background-color: #161619;

  height: 405px;
  width: 500px;
  border-radius: 6px;
}
